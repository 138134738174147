import * as THREE from 'three';

const _v1 = new THREE.Vector3();
const _v2 = new THREE.Vector3();

export class Octree {

    constructor(box) {

        this.polygons = [];
        this.box = box;
        this.subTrees = [];

    }

    addPolygon(polygon) {

        if (!this.bounds) this.bounds = new THREE.Box3();
        for (var i = 0; i < polygon.vertices.length; i++) {

            const position = polygon.vertices[i].pos;

            this.bounds.min.x = Math.min(this.bounds.min.x, position.x);
            this.bounds.min.y = Math.min(this.bounds.min.y, position.y);
            this.bounds.min.z = Math.min(this.bounds.min.z, position.z);
            this.bounds.max.x = Math.max(this.bounds.max.x, position.x);
            this.bounds.max.y = Math.max(this.bounds.max.y, position.y);
            this.bounds.max.z = Math.max(this.bounds.max.z, position.z);
        }
        this.polygons.push(polygon);
        return this;

    }

    calcBox() {
        if (!this.bounds) this.bounds = new THREE.Box3();
        this.box = this.bounds.clone();

        this.box.min.x -= 0.01
        this.box.min.y -= 0.01
        this.box.min.z -= 0.01

        this.box.max.x += 0.01
        this.box.max.y += 0.01
        this.box.max.z += 0.01

        return this;

    }

    split(level) {

        if (!this.box || this.box.max.x - this.box.min.x < 0.01) {
            return;
        }

        const subTrees = [];

        const halfsize = _v2.copy(this.box.max).sub(this.box.min).multiplyScalar(0.5);

        for (let x = 0; x < 2; x++) {

            for (let y = 0; y < 2; y++) {

                for (let z = 0; z < 2; z++) {

                    const box = new THREE.Box3();

                    const v = _v1.set(x, y, z);

                    box.min.copy(this.box.min).add(v.multiply(halfsize));
                    box.max.copy(box.min).add(halfsize);
                    subTrees.push(new Octree(box));

                }

            }

        }

        let thisPolygons = [];
        for (let j = 0; j < this.polygons.length; j++) {

            const polygon = this.polygons[j];

            var putInSubTree = false;
            for (let i = 0; i < subTrees.length; i++) {

                if (subTrees[i].box.containsBox(polygon.getBounds())) {

                    subTrees[i].polygons.push(polygon);
                    putInSubTree = true;
                    break;
                } 
            }
            if (!putInSubTree) {
                thisPolygons.push(polygon);
            }
        }

        this.polygons = thisPolygons;
        

        for (let i = 0; i < subTrees.length; i++) {

            const len = subTrees[i].polygons.length;

            if (len > 8 && level < 16) {

                subTrees[i].split(level + 1);

            }

            if (len !== 0) {

                this.subTrees.push(subTrees[i]);

            }

        }

        return this;

    }

    build() {

        this.calcBox();
        this.split(0);
        return this;

    }

    getIntersecting(polygon, polygons) {
        if (!polygons) {
            polygons = new Set();
        }

        if (!polygon.getBounds().intersects(this.box)) {
            return;
        }

        for (let j = 0; j < this.polygons.length; j++) {

            const polygonJ = this.polygons[j];
            if (polygonJ !== polygon && polygonJ.getBounds().intersects(polygon.getBounds())) {
                polygons.add(polygonJ);
            }
        }

        for (let i = 0; i < this.subTrees.length; i++) {

            this.subTrees[i].getIntersecting(polygon, polygons);
        }

        return polygons;
    }

    getRayPolygons(ray, polygons) {
        for (let i = 0; i < this.subTrees.length; i++) {

            const subTree = this.subTrees[i];
            if (!ray.intersectsBox(subTree.box)) continue;

            if (subTree.polygons.length > 0) {

                for (let j = 0; j < subTree.polygons.length; j++) {

                    if (polygons.indexOf(subTree.polygons[j]) === - 1) {
                        polygons.push(subTree.polygons[j]);
                    }
                }
            } else {

                subTree.getRaypolygons(ray, polygons);
            }

        }

        return polygons;
    }
}