import Shape from "./Shape";
import { CSG } from "./CSG";
import Color from "./Color";
import Cylinder from "./Cylinder";
import Group from "./Group";
import * as THREE from "three";
import { Vector3 } from "three";

export default class Cube extends Shape {
    constructor(width, heigth, depth) {
        super();
        this.adjustables.color = new Color(0., 0., 1.)
        this.setAdjustables(
            {
                width,
                heigth,
                depth,
                type: {
                    width: 'double',
                    heigth: 'double',
                    depth: 'double',
                }
            }
        );
    }

    clone() {
        var shape = new Cube();
        this.cloneAttributes(shape, this);
        return shape;
    }

    make() {
        this.csg = CSG.cube({
            center: new THREE.Vector3(),
            radius: new THREE.Vector3(this.adjustables.width, this.adjustables.heigth, this.adjustables.depth)
        });
    }

    fillet(fillet, filletSmoothness) {
        const cubeWidth = this.adjustables.width;
        const cubeHeight = this.adjustables.heigth;
        const cubeDepth = this.adjustables.depth;
        var filletXCut = new Group([
            // X
            new Cylinder(
                new Vector3(-cubeWidth, cubeHeight - fillet, cubeDepth - fillet),
                new Vector3(cubeWidth, cubeHeight - fillet, cubeDepth - fillet),
                fillet, filletSmoothness),
            new Cylinder(
                new Vector3(-cubeWidth, cubeHeight - fillet, -cubeDepth + fillet),
                new Vector3(cubeWidth, cubeHeight - fillet, -cubeDepth + fillet),
                fillet, filletSmoothness),

            new Cylinder(
                new Vector3(-cubeWidth, -cubeHeight + fillet, cubeDepth - fillet),
                new Vector3(cubeWidth, -cubeHeight + fillet, cubeDepth - fillet),
                fillet, filletSmoothness),
            new Cylinder(
                new Vector3(-cubeWidth, -cubeHeight + fillet, -cubeDepth + fillet),
                new Vector3(cubeWidth, -cubeHeight + fillet, -cubeDepth + fillet),
                fillet, filletSmoothness),

            new Cube(cubeWidth + fillet, cubeHeight - fillet, cubeDepth - fillet),
            new Cube(cubeWidth, cubeHeight + fillet, cubeDepth - fillet),
            new Cube(cubeWidth, cubeHeight - fillet, cubeDepth + fillet),

        ]);

        filletXCut.adjustables.hole = true;
        filletXCut = new Group([this, filletXCut]);

        var filletYCut = new Group([

            // Y
            new Cylinder(
                new Vector3(cubeWidth - fillet, -cubeHeight, cubeDepth - fillet),
                new Vector3(cubeWidth - fillet, cubeHeight, cubeDepth - fillet),
                fillet, filletSmoothness),
            new Cylinder(
                new Vector3(cubeWidth - fillet, -cubeHeight, -cubeDepth + fillet),
                new Vector3(cubeWidth - fillet, cubeHeight, -cubeDepth + fillet),
                fillet, filletSmoothness),

            new Cylinder(
                new Vector3(-cubeWidth + fillet, -cubeHeight, cubeDepth - fillet),
                new Vector3(-cubeWidth + fillet, cubeHeight, cubeDepth - fillet),
                fillet, filletSmoothness),
            new Cylinder(
                new Vector3(-cubeWidth + fillet, -cubeHeight, -cubeDepth + fillet),
                new Vector3(-cubeWidth + fillet, cubeHeight, -cubeDepth + fillet),
                fillet, filletSmoothness),

            new Cube(cubeWidth - fillet, cubeHeight + fillet, cubeDepth - fillet),
            new Cube(cubeWidth + fillet, cubeHeight + fillet, cubeDepth - fillet),
            new Cube(cubeWidth - fillet, cubeHeight + fillet, cubeDepth + fillet),

        ]);

        filletYCut.adjustables.hole = true;
        filletYCut = new Group([this, filletYCut]);

        var filletZCut = new Group([
            // Z
            new Cylinder(
                new Vector3(cubeWidth - fillet, cubeHeight - fillet, -cubeDepth),
                new Vector3(cubeWidth - fillet, cubeHeight - fillet, cubeDepth),
                fillet, filletSmoothness),
            new Cylinder(
                new Vector3(cubeWidth - fillet, -cubeHeight + fillet, -cubeDepth),
                new Vector3(cubeWidth - fillet, -cubeHeight + fillet, cubeDepth),
                fillet, filletSmoothness),

            new Cylinder(
                new Vector3(-cubeWidth + fillet, cubeHeight - fillet, -cubeDepth),
                new Vector3(-cubeWidth + fillet, cubeHeight - fillet, cubeDepth),
                fillet, filletSmoothness),
            new Cylinder(
                new Vector3(-cubeWidth + fillet, -cubeHeight + fillet, -cubeDepth),
                new Vector3(-cubeWidth + fillet, -cubeHeight + fillet, cubeDepth),
                fillet, filletSmoothness),

            new Cube(cubeWidth - fillet, cubeHeight - fillet, cubeDepth + fillet),
            new Cube(cubeWidth - fillet, cubeHeight + fillet, cubeDepth + fillet),
            new Cube(cubeWidth + fillet, cubeHeight - fillet, cubeDepth + fillet),

        ]);

        filletZCut.adjustables.hole = true;
        filletZCut = new Group([this, filletZCut]);

        const filletCut = new Group([filletXCut, filletYCut, filletZCut]);
        filletCut.adjustables.hole = true;

        return new Group([this, filletCut]);
    }
}