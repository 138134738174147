import React from 'react';
import { TextField, Stack } from '@mui/material'

export default class ProductMetadataControls extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.metadata) {
            return <></>;
        }

        const propertyControls = []
        for (const key in this.props.metadata) {
            const value = this.props.metadata[key];

            if (value === "false" || value === "true") {

                propertyControls.push(this.booleanPropertyControl(key, value));

            } else if (value.includes(".")) {

                propertyControls.push(this.doublePropertyControl(key, value));

            } else {

                propertyControls.push(this.intPropertyControl(key, value));

            }
        }

        return (
            <Stack spacing={3}>
                {propertyControls}
            </Stack>
        );
    }

    intPropertyControl(key, value) {
        return <TextField key={key} className={"item"}
            label={key.replace(/([A-Z])/g, ' $1')
                .replace(/^./, (str) => {
                    return str.toUpperCase();
                })}
            size="small" variant="outlined" type="number"
            value={value}
            onChange={(e => {
                const originalValue = this.props.metadata[key];
                this.props.metadata[key] = e.target.value;
                this.props.onChanged({ key: key, oldValue: originalValue, newValue: e.target.value.toString() });
            })} />;
    }

    doublePropertyControl(key, value) {
        return <TextField key={key} className={"item"}
            label={key.replace(/([A-Z])/g, ' $1')
                .replace(/^./, (str) => {
                    return str.toUpperCase();
                })}
            size="small" variant="outlined" type="number"
            value={value}
            onChange={(e => {
                const originalValue = this.props.metadata[key];
                this.props.metadata[key] = e.target.value;
                this.props.onChanged({ key: key, oldValue: originalValue, newValue: e.target.value.toString() });
            })} />;
    }

    booleanPropertyControl(key, value) {
        return <div key={key} className={"row"}>
            <FormLabel key={key + "label"} component="legend">{
                key.replace(/([A-Z])/g, ' $1')
                    .replace(/^./, (str) => {
                        return str.toUpperCase();
                    })
            }</FormLabel>
            <Checkbox
                className={"item"}
                checked={value}
                onChange={(e) => {
                    const originalValue = this.props.metadata[key];
                    this.props.metadata[key] = e.target.value;
                    this.props.onChanged({ key: key, oldValue: originalValue, newValue: e.target.value.toString() });
                }} />
        </div>;
    }

}