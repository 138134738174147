import React from 'react';
import ShapeView from './ShapeView';

export default class AssemblyView extends React.Component {

    render() {
        const assembly = this.props.assembly;
        return assembly.shapes.map(shape =>
                    <ShapeView
                        disableControls={true}
                        key={shape.id}
                        orbitControls={this.props.orbitControls}
                        transformControl={this.props.transformControl}
                        shape={shape} />);
    }
}