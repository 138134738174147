import React from 'react';
import { Canvas } from '@react-three/fiber'
import { GizmoHelper, OrbitControls, GizmoViewport } from '@react-three/drei'
import AssemblyView from './AssemblyView'
import WorkPlane from './WorkPlane'

export default class Viewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            transformControl: "rotate",
        }
        this.orbit = React.createRef();
        this.mainLightRef = React.createRef();
    }


    render() {
        const assembly = this.props.document.assembly;
        if (!assembly) {
            return;
        }

        const bounds = assembly.bounds().calculateWidthVector();
        const max = Math.max(bounds.x, bounds.y, bounds.z);

        return (
            <Canvas className="canvas" camera={{ position: [max, max, max] }}
                onPointerMissed={(event) => {
                    if (this.props.onClick) {

                        this.props.onClick(event)
                    }
                }}>

                <AssemblyView
                    assembly={this.props.document.assembly}
                    orbitControls={this.orbit}
                    transformControl={this.state.transformControl} />

                {/* <WorkPlane size={[20, 20]} inchPerDiv={0.5} /> */}

                {/* <GizmoHelper
                    alignment='bottom-right'
                    margin={[80, 80]}>
                    <GizmoViewport />
                </GizmoHelper> */}

                {/*An ambient light that creates a soft light against the object */}
                <ambientLight ref={this.mainLightRef} color={"white"} intensity={0.1} />
                {/* <pointLight position={[0, -10, 15]} intensity={1} /> */}
                {/*An directional light which aims form the given position */}
                {/* <directionalLight position={[10, 10, 5]} intensity={1} /> */}
                {/*An point light, basically the same as directional. This one points from under */}
                <directionalLight position={[20, 0, 0]} intensity={.4} />
                <directionalLight position={[-20, 0, 0]} intensity={.4} />
                <directionalLight position={[0, -20, 0]} intensity={.4} />
                <directionalLight position={[0, 20, 0]} intensity={.4} />
                <directionalLight position={[0, 0, 20]} intensity={.4} />
                <directionalLight position={[0, 0, -20]} intensity={.4} />

                <OrbitControls ref={this.orbit} enableDamping={false} makeDefault />
            </Canvas>
        );
    }

}