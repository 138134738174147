import safeEval from 'safe-eval'
import Assembly from './Assembly';
import Group from './Group';
import Cube from './Cube';
import Cylinder from './Cylinder';
import Sphere from './Sphere';
import Cone from './Cone';
import Color from './Color';
import { Vector3 } from 'three';
import { Euler } from 'three';
import { Quaternion } from 'three';
import { Matrix4 } from 'three';
import * as THREE from 'three';

export default class Document {

    constructor() {
        this.assembly = new Assembly();
        this.stringValue =
            "function generateRootAssembly() {\n" +
            "    const assembly = new Assembly();\n" +
            "    return assembly;\n" +
            "}";

        this.load(this.stringValue);
    }

    load(stringValue) {
        const newAssembly = safeEval(stringValue + "()",
            {
                Assembly,
                Group,
                Cube,
                Cylinder,
                Cone,
                Sphere,
                Color,
                Vector3,
                Euler,
                Quaternion,
                Matrix4,
                THREE,
            });
        if (newAssembly instanceof Assembly) {
            this.stringValue = stringValue;
            this.assembly = newAssembly;
        }
    }

    save() {

    }

    undo() {

    }

    redo() {

    }

    
}
