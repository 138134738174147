import Shape from "./Shape";
import { CSG } from "./CSG";
import Color from "./Color";

export default class Cylinder extends Shape {
    constructor(start, end, radius, slices = 16) {
        super();
        this.adjustables.color = new Color(1., 0., 0.)
        this.setAdjustables(
            {
                start,
                end,
                radius,
                slices,
                type: {
                    radius: 'double',
                    slices: 'int',
                }
            }
        );
    }

    clone() {
        var shape = new Cylinder();
        this.cloneAttributes(shape, this);
        return shape;
    }

    make() {
        this.csg = CSG.cylinder({
            start: this.adjustables.start,
            end: this.adjustables.end,
            radius: this.adjustables.radius,
            slices: this.adjustables.slices
        });
    }

}