import Shape from "./Shape";
import { CSG } from "./CSG";
import * as THREE from "three";
import Color from "./Color";

export default class Sphere extends Shape {

    constructor(radius, slices = 16, stacks = 8) {
        super();
        this.adjustables.color = new Color(0., 1., 0.);
        this.setAdjustables(
            {
                radius,
                slices,
                stacks,
                type: {
                    radius: 'double',
                    slices: 'int',
                    stacks: 'int',
                }
            }
        );
    }

    make() {
        this.csg = CSG.sphere({
            center: new THREE.Vector3(),
            radius: this.adjustables.radius,
            slices: this.adjustables.slices,
            stacks: this.adjustables.stacks
        });
    }

    clone() {
        var shape = new Sphere();
        this.cloneAttributes(shape, this);
        return shape;
    }

}