import create from "zustand";
import React from 'react';
import Document from '../../model/p3cad/Document';

export const useStore = create((set) => ({
    selected: [],
    selectedMeshs: [],
    document: new Document(),
}));

export const withStore = BaseComponent => props => {
    const store = useStore();
    return <BaseComponent {...props} store={store} />;
};