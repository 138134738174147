// # class Polygon

// Represents a convex polygon. The vertices used to initialize a polygon must
// be coplanar and form a convex loop. They do not have to be `Vertex`
// instances but they must behave similarly (duck typing can be used for
// customization).
// 
// Each convex polygon has a `shared` property, which is shared between all
// polygons that are clones of each other or were split from the same polygon.
// This can be used to define per-polygon properties (such as surface color).
import Plane from "./Plane";
import Bounds from "./Bounds";
import Vertex from "./Vertex";
import { approxZero } from "./Vector3Helpers"
import * as THREE from "three";
import { Vector3 } from "three";

export default class Polygon {
    constructor(vertices, shared) {
        this.vertices = vertices;
        this.shared = shared;
        this.plane = Plane.fromAllVertices(vertices);
        if (this.vertices && this.vertices.length > 0 && !this.vertices[0].normal) {
            this.vertices.forEach(
                (v) => {
                    v.normal = this.plane.normal.clone();
                }
            );
        }
    }

    getBounds() {
        if (!this.bounds) {
            this.bounds = this.calculateBounds();
        }
        return this.bounds;
    }

    calculateBounds() {
        const bounds = new Bounds();

        const numVertices = this.vertices.length;
        for (var j = 0; j < numVertices; j++) {
            bounds.add(this.vertices[j].pos);
        }

        return bounds;
    }

    clone() {
        return new Polygon(this.vertices.map(v => v.clone()), this.shared);
    }

    flip() {
        this.vertices.reverse().forEach(v => v.flip())
        this.plane.flip();
    }

    transform(matrix4) {
        this.vertices.forEach(
            (v) => {
                v.transform(matrix4);
            }
        );

        this.plane = Plane.fromPoints(this.vertices[0].pos, this.vertices[1].pos, this.vertices[2].pos);
        this.vertices.forEach(
            (v) => {
                v.normal = this.plane.normal.clone();
            }
        );
        return this;
    }

    pointOnEdge(p) {
        // P not on the plane
        if (!this.plane.onPlane(p)) {
            return false;
        }
        
        if (this.vertices.some(vector => p.distanceToSquared(vector.pos) < Polygon.EPSILON_SQ)) {
            return false;
        }

        // if P is on the plane, we proceed with projection to XY plane
        //
        // P1--P------P2
        //     ^
        //     |
        // P is on the segment if( dist(P1,P) + dist(P2,P) - dist(P1,P2) < TOL)
        for (var i = 0; i < this.vertices.length; i++) {

            var p1 = this.vertices[i].pos;
            var p2 = this.vertices[(i + 1) % this.vertices.length].pos;

            var onASegment = p1.distanceTo(p)
                + p2.distanceTo(p)
                - p1.distanceTo(p2) < Polygon.EPSILON;

            if (onASegment) {
                return true;
            }
        }

        return false;

    }

    add(p) {
        for (var i = 0; i < this.vertices.length; i++) {

            var p1 = this.vertices[i].pos;
            const j = (i + 1) % this.vertices.length;
            var p2 = this.vertices[j].pos;

            var onASegment = p1.distanceTo(p)
                + p2.distanceTo(p)
                - p1.distanceTo(p2) < Polygon.EPSILON;

            if (onASegment) {
                if (j === this.vertices.length) {
                    this.vertices.push(new Vertex(p.clone(), this.plane.normal.clone()));
                } else {
                    this.vertices.splice(j, 0, new Vertex(p.clone(), this.plane.normal.clone()));
                }
                return;
            }
        }
    }

    split(p) {
        for (var i = 0; i < this.vertices.length; i++) {

            const j = (i + 1) % this.vertices.length;
            var k = (i - 1);
            if (k < 0) {
                k = this.vertices.length -1;
            }

            var p1 = this.vertices[i].pos;
            var p2 = this.vertices[j].pos;

            var onASegment = p1.distanceTo(p)
                + p2.distanceTo(p)
                - p1.distanceTo(p2) < Polygon.EPSILON;

            if (onASegment) {

                const split1Verts = [
                    this.vertices[i].clone(),
                    new Vertex(p.clone(), this.plane.normal.clone()),
                    this.vertices[k].clone()
                ];

                const split2Verts = []
                for (var l = 0; l < this.vertices.length; l++) {
                    if (l === i) {
                        split2Verts.push(new Vertex(p.clone(), this.plane.normal.clone()));
                    } else {
                        split2Verts.push(this.vertices[l].clone());
                    }
                }

                return [new Polygon(split1Verts), new Polygon(split2Verts)];
            }
        }

        return [this];
    }

    isCCW() {

        if (this.vertices.length < 3) {
            return false;
        }

        // search highest left vertex
        var highestLeftVertexIndex = 0;
        var highestLeftVertex = this.vertices[0];
        for (var i = 0; i < this.vertices.length; i++) {
            var v = this.vertices[i];

            if (v.pos.y > highestLeftVertex.pos.y) {
                highestLeftVertex = v;
                highestLeftVertexIndex = i;
            } else if (v.pos.y === highestLeftVertex.pos.y
                && v.pos.x < highestLeftVertex.pos.x) {
                highestLeftVertex = v;
                highestLeftVertexIndex = i;
            }
        }

        // determine next and previous vertex indices
        var nextVertexIndex = (highestLeftVertexIndex + 1) % this.vertices.length;
        var prevVertexIndex = highestLeftVertexIndex - 1;
        if (prevVertexIndex < 0) {
            prevVertexIndex = this.vertices.length - 1;
        }
        var nextVertex = this.vertices[nextVertexIndex];
        var prevVertex = this.vertices[prevVertexIndex];

        // edge 1
        var a1 = this.normalizedX(highestLeftVertex.pos, nextVertex.pos);

        // edge 2
        var a2 = this.normalizedX(highestLeftVertex.pos, prevVertex.pos);

        // select vertex with lowest x value
        var selectedVIndex;

        if (a2 > a1) {
            selectedVIndex = nextVertexIndex;
        } else {
            selectedVIndex = prevVertexIndex;
        }

        if (selectedVIndex === 0
            && highestLeftVertexIndex == this.vertices.length - 1) {
            selectedVIndex = this.vertices.length;
        }

        if (highestLeftVertexIndex === 0
            && selectedVIndex === this.vertices.length - 1) {
            highestLeftVertexIndex = this.vertices.length;
        }

        // indicates whether edge points from highestLeftVertexIndex towards
        // the sel index (ccw)
        return selectedVIndex > highestLeftVertexIndex;
    }

    normalizedX(v1, v2) {
        var v2MinusV1 = v2.clone().sub(v1);

        return v2MinusV1.clone().divide(v2MinusV1.length()).multiply(new THREE.Vector3(1., 0., 0.)).x;
    }

    area() {
        if (this.vertices.length < 3) {

            return 0
        }// not a plane - no area

        var total = new Vector3();
        for (var i = 0; i < this.vertices.length; i++) {

            var vi1 = this.vertices[i].pos;
            var vi2;
            if (i === this.vertices.length - 1) {
                vi2 = this.vertices[0].pos
            } else {
                vi2 = this.vertices[i + 1].pos
            }
            total.add(vi1.clone().cross(vi2));
        }

        return Math.abs(total.dot(this.plane.normal) / 2)
    }

    getHash() {
        if (!this.hash) {

            this.hash = "";
            for (var i = 0; i < this.vertices.length; i++) {
                this.hash = this.hash + "," + this.vertices[i].getHash();
            }

        }
        return this.hash;
    }
}


Polygon.EPSILON = 1e-5;
Polygon.EPSILON_SQ = Polygon.EPSILON * Polygon.EPSILON;