import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { WebGLRenderer } from 'three';

const thumb = {};
const thumbInner = {};
const thumbsContainer = {};
const img = {};

export default function InstantQuote(props) {
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: async acceptedFiles => {
            const previewedFiles = await Promise.all(acceptedFiles.map(async dropfile => {

                function readFile(file) {
                    return new Promise((resolve, reject) => {
                        // Create file reader
                        let reader = new FileReader()

                        // Register event listeners
                        reader.addEventListener("loadend", e => resolve(e.target.result))
                        reader.addEventListener("error", reject)

                        // Read file
                        reader.readAsArrayBuffer(file)
                    })
                }

                const stlData = await readFile(dropfile)
                dropfile.preview = stl2png(stlData);
                console.log(dropfile, {
                    width: 100,
                    height: 100,
                });
                return dropfile;
            }))

            console.log(previewedFiles);
            setFiles(previewedFiles);


        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));


    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
        </section>
    );
}

const DEFAULTS = {
    width: 768,
    height: 512,
    backgroundColor: new THREE.Color(0xffffff),
    backgroundAlpha: 0,
    cameraPosition: [0, -25, 20],
    materials: [makeStandardMaterial(1, 0xb8cad8)],
    edgeMaterials: [],
    lights: [makeDirectionalLight(0, -25, 100, 0xffffff, 1.5), makeAmbientLight(0x666666)],
};


function stl2png(stlData, options = {}) {
    // Prepare the scene, renderer, and camera
    const width = options.width ?? DEFAULTS.width;
    const height = options.height ?? DEFAULTS.height;
    const backgroundColor = options.backgroundColor ?? DEFAULTS.backgroundColor;
    const backgroundAlpha = options.backgroundAlpha ?? DEFAULTS.backgroundAlpha;
    const camera = new THREE.PerspectiveCamera(30, width / height, 1, 1000);
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({
        preserveDrawingBuffer: true,
        alpha: !backgroundAlpha
    });
    renderer.setSize(width, height, false);
    renderer.setClearColor(backgroundColor, backgroundAlpha);
    scene.background = backgroundColor;
    
    const geometry = getGeometry(stlData);

    // Configure camera with user-set position, then move it in-or-out depending on
    // the size of the model that needs to display
    camera.position.x = options.cameraPosition?.[0] ?? DEFAULTS.cameraPosition[0];
    camera.position.y = options.cameraPosition?.[1] ?? DEFAULTS.cameraPosition[1];
    camera.position.z = options.cameraPosition?.[2] ?? DEFAULTS.cameraPosition[2];
    camera.lookAt(geometry.boundingSphere.center);

    // (re)Position the camera
    // See http://stackoverflow.com/questions/14614252/how-to-fit-camera-to-object
    const fov = camera.fov * (Math.PI / 180);
    const distance = Math.abs(geometry.boundingSphere.radius / Math.sin(fov / 2));
    const newPosition = camera.position.clone().normalize().multiplyScalar(distance).add(geometry.boundingSphere.center);
    camera.position.set(newPosition.x, newPosition.y, newPosition.z);
    const distanceToCenter = camera.position.clone().sub(geometry.boundingSphere.center).length();
    camera.far = distanceToCenter + geometry.boundingSphere.radius * 1.1;
    camera.near = distanceToCenter - geometry.boundingSphere.radius * 1.1;
    camera.updateProjectionMatrix();

    (options.lights ?? DEFAULTS.lights).forEach((light) => scene.add(light));

    // Get materials according to requested characteristics of the output render
    (options.materials ?? DEFAULTS.materials).forEach((m) => scene.add(new THREE.Mesh(geometry, m)));
    if (!options.edgeMaterials || options.edgeMaterials.length) {
        const edges = new THREE.EdgesGeometry(geometry);
        (options.edgeMaterials ?? DEFAULTS.edgeMaterials).forEach((m) => scene.add(new THREE.LineSegments(edges, m)));
    }

    renderer.render(scene, camera);
    var strMime = "image/jpeg";
    return renderer.domElement.toDataURL(strMime);
}

function getGeometry(stlData) {
    const loader = new STLLoader();
    const geometry = loader.parse(stlData);
    geometry.computeVertexNormals();
    geometry.computeBoundingSphere();
    geometry.computeBoundingBox();
    geometry.center();
    return geometry;
}

export function makeLambertMaterial(opacity, envMap) {
    return makeCanvasMaterial(
        new THREE.MeshLambertMaterial({
            envMap: envMap,
            transparent: true,
            side: THREE.DoubleSide,
            opacity: opacity,
        }),
        0
    );
}

export function makeTexture(imageData, hasAlpha) {
    const img = new Canvas.Image();
    img.src = imageData;

    const texture = new THREE.Texture();
    texture.format = hasAlpha ? THREE.RGBFormat : THREE.RGBAFormat;
    texture.image = img;
    texture.needsUpdate = true;
   
    return texture;
}


export function makeNormalMaterial(opacity) {
    return makeCanvasMaterial(
        new THREE.MeshNormalMaterial({
            side: THREE.DoubleSide,
            transparent: true,
            opacity: opacity,
        }),
        0.2
    );
}

export function makeStandardMaterial(opacity, color) {
    return makeCanvasMaterial(
        new THREE.MeshStandardMaterial({
            side: THREE.DoubleSide,
            transparent: true,
            opacity: opacity,
            color: color,
        }),
        0.55
    );
}

export function makeAmbientLight(color, intensity) {
    return new THREE.AmbientLight(color, intensity);
}

export function makeDirectionalLight(x, y, z, color, intensity) {
    const directionalLight = new THREE.DirectionalLight(color, intensity);
    directionalLight.position.set(x, y, z);

    directionalLight.castShadow = true;

    const d = 1;
    directionalLight.shadow.camera.left = -d;
    directionalLight.shadow.camera.right = d;
    directionalLight.shadow.camera.top = d;
    directionalLight.shadow.camera.bottom = -d;

    directionalLight.shadow.camera.near = 1;
    directionalLight.shadow.camera.far = 4;

    directionalLight.shadow.bias = -0.002;
    return directionalLight;
}

export function makeCanvasMaterial(material, overDraw) {
    (material).overDraw = overDraw;
    return material;
}
