
export default class Color {
    constructor(red, green, blue, alpha = 1.) {

        if (typeof red === 'string') {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(red);
            this.red = parseInt(result[1], 16);
            this.green = parseInt(result[2], 16);
            this.blue = parseInt(result[3], 16);
            this.alpha = alpha;
        } else {
            this.red = red;
            this.green = green;
            this.blue = blue;
            this.alpha = alpha;
        }
    }

    clone() {
        var color = new Color();
        color.red = this.red;
        color.green = this.green;
        color.blue = this.blue;
        color.alpha = this.alpha;
        return color;
    }

    toRGBAHtml() {
        return "rgba(" + this.red * 255 + "," + this.green * 255 + "," + this.blue * 255 + "," + this.alpha * 255 + ")";
    }

    toHex() {
        var red = this.red * 255 < 0 ? 0 : this.red * 255 > 255 ? 255 : this.red * 255;
        var green = this.green * 255 < 0 ? 0 : this.green * 255 > 255 ? 255 : this.green * 255;
        var blue = this.blue * 255 < 0 ? 0 : this.blue * 255 > 255 ? 255 : this.blue * 255;

        var hex = "#" +
            ("0" + parseInt(red, 10).toString(16)).slice(-2) +
            ("0" + parseInt(green, 10).toString(16)).slice(-2) +
            ("0" + parseInt(blue, 10).toString(16)).slice(-2)
        return hex;
    }

    isBlack() {
        return this.red < 0.1 && this.blue < 0.1 && this.green < 0.1;
    }

}
