import * as THREE from 'three';
import { Vector3 } from 'three';

export default class Bounds {

    constructor() {
    }

    add(position) {
        if (!this.min) {
            this.min = new Vector3().copy(position);
            this.max = new Vector3().copy(position);
            return;
        }

        if (position.x < this.min.x) {
            this.min.x = position.x;
        } else if (position.x > this.max.x) {
            this.max.x = position.x;
        }

        if (position.y < this.min.y) {
            this.min.y = position.y;
        } else if (position.y > this.max.y) {
            this.max.y = position.y;
        }

        if (position.z < this.min.z) {
            this.min.z = position.z;
        } else if (position.z > this.max.z) {
            this.max.z = position.z;
        }
    }

    getMaxWidth() {
        const widthx = Math.abs(this.max.x - this.min.x);
        const widthy = Math.abs(this.max.y - this.min.y);
        const widthz = Math.abs(this.max.z - this.min.z);

        return Math.max(widthx, widthy, widthz);
    }

    addBounds(bounds) {
        if (!this.min) {
            this.min = new Vector3().copy(bounds.min);
            this.max = new Vector3().copy(bounds.max);
            return;
        }
        if (bounds.min.x < this.min.x) {
            this.min.x = bounds.min.x;
        }
        if (bounds.min.y < this.min.y) {
            this.min.y = bounds.min.y;
        }
        if (bounds.min.z < this.min.z) {
            this.min.z = bounds.min.z;
        }
        
        if (bounds.max.x > this.max.x) {
            this.max.x = bounds.max.x;
        }
          
        if (bounds.max.y > this.max.y) {
            this.max.y = bounds.max.y;
        }
        if (bounds.max.z > this.max.z) {
            this.max.z = bounds.max.z;
        }
    }

    intersects(b) {

        if (b.min.x > this.max.x || b.max.x < this.min.x) {
            return false;
        }

        if (b.min.y > this.max.y || b.max.y < this.min.y) {
            return false;
        }

        if (b.min.z > this.max.z || b.max.z < this.min.z) {
            return false;
        }

        return true;

    }

    intersectsTol(b, eps = 1e-5) {

        if (b.min.x - eps > this.max.x || b.max.x + eps < this.min.x) {
            return false;
        }

        if (b.min.y - eps > this.max.y || b.max.y + eps < this.min.y) {
            return false;
        }

        if (b.min.z - eps > this.max.z || b.max.z + eps < this.min.z) {
            return false;
        }

        return true;

    }

    calculateCenter() {
        return this.min.clone().add(this.max).multiplyScalar(0.5);
    }

    calculateWidthVector() {
        const widthVec =  this.max.clone().sub(this.min);

        widthVec.x = Math.abs(widthVec.x);
        widthVec.y = Math.abs(widthVec.y);
        widthVec.z = Math.abs(widthVec.z);

        return widthVec;
    }
}