import Shape from "./Shape";
import { Matrix4, Vector3 } from 'three';

export default class Group extends Shape {
    constructor(shapes) {
        super();

        this.shapes = shapes;
        if (shapes === undefined) {
            return;
        }
        const nonHoles = shapes.filter(s => !s.adjustables.hole);
        if (nonHoles.length === 0) {
            return;
        }

        const anchorShape = nonHoles[0];
        this.adjustables.position = anchorShape.adjustables.position.clone();
        this.adjustables.quaternion = anchorShape.adjustables.quaternion.clone();
        
        shapes.forEach(shape => {
            // combinedMatrix = rotationMatrix * scaleMatrix * translationMatrix;
            const groupTransfromInverse = new Matrix4().compose(this.adjustables.position, this.adjustables.quaternion, new Vector3(1., 1., 1.)).invert();
            const shapeTransfrom = new Matrix4().compose(shape.adjustables.position, shape.adjustables.quaternion, new Vector3(1., 1., 1.));
            const shapeTransfromInGroup = groupTransfromInverse.multiply(shapeTransfrom);
            shapeTransfromInGroup.decompose(shape.adjustables.position, shape.adjustables.quaternion, new Vector3(1., 1., 1.));
        });

        nonHoles.forEach(shape => this.apply(shape));
        shapes.filter(s => s.adjustables.hole).forEach(shape => this.apply(shape));

        this.adjustables.color = anchorShape.adjustables.color.clone();
    }

    apply(shape) {

        var nextCSG = shape.csg.clone();
        if (nextCSG) {
            const shapeTransfromInGroup = new Matrix4().compose(shape.adjustables.position, shape.adjustables.quaternion, new Vector3(1., 1., 1.));
            
            nextCSG.transform(shapeTransfromInGroup);
            if (this.csg == null) {
                this.csg = nextCSG;
            } else {
                if (shape.adjustables.hole) {
                    this.csg = this.csg.subtract(nextCSG);
                } else {
                    this.csg = this.csg.union(nextCSG);
                }
            }
        }
    }

    add(shape) {
        this.shapes.push(shape);
    }

    remove(shape) {
        this.shapes.remove(shape);
    }
}