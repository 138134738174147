import fs from 'fs';
import yaml from 'js-yaml';
import Color from './Color.js';
import { v4 as uuidv4 } from 'uuid';
import * as THREE from 'three';

export default class Shape {
    constructor() {
        this.name = "shape"
        this.id = uuidv4();
        this.assembly = null;

        this.adjustables = {
            position: new THREE.Vector3(),
            quaternion: new THREE.Quaternion(0., 0., 0., 1.),
            hole: false,
            color: new Color(0., 1., 0.),
            type: {},
        }
    }

    setAdjustables(adjustables) {
        for (const key in adjustables) {
            this.adjustables[key] = adjustables[key];
        }
        this.make();
    }

    make() {
        // override
    }

    clone() {
        var shape = new Shape();
        this.cloneAttributes(shape, this);
        return shape;
    }

    cloneAttributes(clonie, original) {
        clonie.adjustables = { ...original.adjustables };
        clonie.csg = original.csg.clone();
    }

    removeFromParent() {
        if (this.parent !== undefined && this.parent !== null) {
            this.parent.remove(this);
        }
    }

    rotate(xDegree, yDegree, zDegree) {
        const euler = new THREE.Euler(xDegree / 180 * Math.PI, yDegree / 180 * Math.PI, zDegree / 180 * Math.PI);
        const rotate = new THREE.Quaternion();
        rotate.setFromEuler(euler);

        this.adjustables.quaternion.multiply(rotate);
    }

    translate(x, y, z) {
       
        this.adjustables.position.set(this.adjustables.position.x + x, this.adjustables.position.y + y, this.adjustables.position.z + z);
    }

    toYamlFile(yamlFilePath) {
        let yamlStr = yaml.safeDump(this);
        fs.writeFileSync(yamlFilePath, yamlStr, 'utf8');
    }

};

Shape.fromYamlFile = function (yamlFilePath) {
    console.log(yamlFilePath)
    var shape = new Shape();
    try {
        let fileContents = fs.readFileSync(yamlFilePath, 'utf8');
        let data = yaml.safeLoad(fileContents);

        console.log(data);
    } catch (e) {
        console.log(e);
    }
    return shape;
};
