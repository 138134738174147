// # class Plane

// Represents a plane in 3D space.
import { Matrix4, Vector2 } from "three";
import { Vector3 } from "three";
import Polygon from "./Polygon";
import { approxNegEquals, approxEqualsScalar, approxEquals } from "./Math3D";

export default class Plane {
    constructor(normal, w) {
        this.normal = normal;
        this.w = w;
    }

    clone() {
        return new Plane(this.normal.clone(), this.w);
    }

    flip() {
        this.normal.negate();
        this.w = -this.w;
    }

    // Split `polygon` by this plane if needed, then put the polygon or polygon
    // fragments in the appropriate lists. Coplanar polygons go into either
    // `coplanarFront` or `coplanarBack` depending on their orientation with
    // respect to this plane. Polygons in front or in back of this plane go into
    // either `front` or `back`.
    splitPolygon(polygon, coplanarFront, coplanarBack, front, back) {

        const dotOfNormals = this.normal.dot(polygon.plane.normal);
        if (Math.abs(Math.abs(dotOfNormals) - 1) < 1e-5) {
            const t0 = this.normal.dot(polygon.vertices[0].pos) - this.w;
            if (t0 < -Plane.EPSILON) {
                back.push(polygon);
            } else if (t0 > Plane.EPSILON) {
                front.push(polygon);
            } else {
                (dotOfNormals > 0 ? coplanarFront : coplanarBack).push(polygon);
            }
            return;
        } 

        // Classify each point as well as the entire polygon into one of the above
        // four classes.
        var polygonType = 0;
        let numVertices = polygon.vertices.length;
        for (var i = 0; i < numVertices; i++) {
            var t = this.normal.dot(polygon.vertices[i].pos) - this.w;
            var type = (t < -Plane.EPSILON) ? Plane.BACK : (t > Plane.EPSILON) ? Plane.FRONT : Plane.COPLANAR;
            polygonType |= type;
            types[i] = type;
        }

        // Put the polygon in the correct list, splitting it when necessary.
        if (polygonType === Plane.FRONT) {
            front.push(polygon);
        } else if (polygonType === Plane.BACK) {
            back.push(polygon);
        } else if (polygonType === Plane.SPANNING) {
            var f = [], b = [];
            for (var i = 0; i < numVertices; i++) {
                var j = (i + 1);
                if (j === numVertices) {
                    j = 0;
                }
                var ti = types[i];
                var tj = types[j];
                var vi = polygon.vertices[i];
                if (ti !== Plane.BACK) {
                    f.push(ti !== Plane.BACK ? vi.clone() : vi);
                }
                if (ti !== Plane.FRONT) {
                    b.push(vi);
                }
                if ((ti | tj) === Plane.SPANNING) {
                    var vj = polygon.vertices[j];
                    var t = (this.w - this.normal.dot(vi.pos)) / this.normal.dot(tempVec.copy(vj.pos).sub(vi.pos));
                    var v = vi.interpolate(vj, t);
                    f.push(v);
                    b.push(v.clone());
                }
            }
            if (f.length >= 3) {
                const frontSplitPolygon = new Polygon(f, polygon.shared);
                frontSplitPolygon.splitFrom = polygon;
                if (!polygon.splits) {
                    polygon.splits = [];
                }
                polygon.splits.push(frontSplitPolygon);
                front.push(frontSplitPolygon);
            }
            if (b.length >= 3) {
                const backSplitPolygon = new Polygon(b, polygon.shared);
                backSplitPolygon.splitFrom = polygon;
                if (!polygon.splits) {
                    polygon.splits = [];
                }
                polygon.splits.push(backSplitPolygon);
                back.push(backSplitPolygon);
            }
        }
    }

    project(p) {
        // dist:   the distance of this plane to the origin
        // anchor: is the anchor point of the plane (closest point to origin)
        // n:      the plane normal
        //
        // a) project (p-anchor) onto n
        var anchor = this.normal.clone().multiplyScalar(this.w);
        var v = anchor.sub(p);
        var dist = v.dot(this.normal);

        return p.clone().sub(this.normal.clone().multiplyScalar(dist));
    }

    distance(p) {
        return p.distanceTo(this.project(p));
    }

    distanceToSquared(p) {
        return p.distanceToSquared(this.project(p));
    }

    onPlane(p) {
        var t = this.normal.dot(p) - this.w;
        return t >= -Plane.EPSILON && t <= Plane.EPSILON
    }

    uv(p) {
        if (!this.M) {

            if (Math.abs(this.normal.z) > 1e-3) {
                this.uAxis = new Vector3(1, 1, -(this.normal.x + this.normal.y) / this.normal.z).normalize();
                this.vAxis = this.uAxis.clone().cross(this.normal).normalize();
            } else if (Math.abs(this.normal.y) > 1e-3) {
                this.uAxis = new Vector3(1, -(this.normal.x + this.normal.z) / this.normal.y, 1).normalize();
                this.vAxis = this.uAxis.clone().cross(this.normal).normalize();
            } else {
                this.uAxis = new Vector3(-(this.normal.y + this.normal.z) / this.normal.x, 1, 1).normalize();
                this.vAxis = this.uAxis.clone().cross(this.normal).normalize();
            }

            var A = this.normal.clone().multiplyScalar(this.w);
            var S = new Matrix4();
            S.set(
                A.x, A.x + this.uAxis.x, A.x + this.vAxis.x, A.x + this.normal.x,
                A.y, A.y + this.uAxis.y, A.y + this.vAxis.y, A.y + this.normal.y,
                A.z, A.z + this.uAxis.z, A.z + this.vAxis.z, A.z + this.normal.z,
                1., 1, 1, 1,
            );

            var D = new Matrix4();
            D.set(
                0, 1, 0, 0,
                0, 0, 1, 0,
                0, 0, 0, 1,
                1, 1, 1, 1,
            );

            this.M = D.multiply(S.invert());
        }
        var uv = p.clone().applyMatrix4(this.M);
        return new Vector2(uv.x, uv.y);
    }

    getHash() {
        if (!this.hash) {
            if (!this.normal.hash) {
                this.normal.hash = `${Math.round(this.normal.x * 1e5)},${Math.round(this.normal.z * 1e5)},${Math.round(this.normal.z * 1e5)}`
            }

            this.hash = `${this.normal.hash},${Math.round(this.w * 1e5)}`
        }
        return this.hash;
    }

    approxEquals(other, eps = 1e-5) {
        return (approxEqualsScalar(this.w, other.w) && approxEquals(this.normal, other.normal))
            || (approxEqualsScalar(this.w, -other.w) && approxNegEquals(this.normal, other.normal));
    }

    isParallel(other) {
        return Math.abs(Math.abs(this.normal.dot(other.normal)) - 1) < 1e-5;
    }

}

// `Plane.EPSILON` is the tolerance used by `splitPolygon()` to decide if a
// point is on the plane.
const types = new Array(1000);
Plane.EPSILON = 1e-5;
Plane.COPLANAR = 0;
Plane.FRONT = 1;
Plane.BACK = 2;
Plane.SPANNING = 3;


const tempVec = new Vector3();

Plane.fromPoints = function (a, b, c) {
    tempVec.copy(a);

    let n = c.clone().sub(b).cross(tempVec.sub(b));

    const lengthSq = n.lengthSq();
    if (lengthSq > 0) {

        n.multiplyScalar(1 / Math.sqrt(lengthSq));

    } else {

        n.x = 0;
        n.y = 0;
        n.z = 0;
    }
    return new Plane(n, n.dot(a));
}

Plane.fromAllPoints = function (points) {

    if (points.length === 3) {
        var triPlane = Plane.fromPoints(points[0], points[1], points[2]);
        return triPlane;
    }

    const normal = new Vector3();
    for (var i = 0; i < points.length; i++) {
        const aI = i;
        const bI = (i + 1) % points.length;
        const cI = (i + 2) % points.length;

        var a = points[aI];
        var b = points[bI];
        var c = points[cI];

        const subNormal = Plane.fromPoints(a, b, c);
        normal.add(subNormal.normal);
    }

    normal.normalize();
    return new Plane(normal, normal.dot(points[0]));
}

Plane.fromAllVertices = function (vertices) {

    if (vertices.length === 3) {
        var triPlane = Plane.fromPoints(vertices[0].pos, vertices[1].pos, vertices[2].pos);
        return triPlane;
    }

    const normal = new Vector3();
    for (var i = 0; i < vertices.length; i++) {
        const aI = i;
        const bI = (i + 1) % vertices.length;
        const cI = (i + 2) % vertices.length;

        var a = vertices[aI].pos;
        var b = vertices[bI].pos;
        var c = vertices[cI].pos;

        const subNormal = Plane.fromPoints(a, b, c);
        normal.add(subNormal.normal);
    }

    normal.normalize();
    return new Plane(normal, normal.dot(vertices[0].pos));
}
