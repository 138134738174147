import React from 'react';
import { CSG } from '../../model/p3cad/CSG.js';
import { TransformControls, Html } from '@react-three/drei'
import { IconButton } from '@mui/material';
import * as THREE from 'three'
import { useStore, withStore } from './Store';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';

class ShapeView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            transformControl: "rotate",
            csg: this.props.shape.csg,
            color: this.props.shape.adjustables.color,
            hole: this.props.shape.adjustables.hole,
            euler: new THREE.Euler().setFromQuaternion(this.props.shape.adjustables.quaternion, THREE.Euler.DefaultOrder, true),
            quaternion: this.props.shape.adjustables.quaternion,
            position: this.props.shape.adjustables.position,
            geom: null
        };

        this.transformer = React.createRef();
        this.geom = React.createRef();
        this.geom2 = React.createRef();
        this.mesh = React.createRef();

    }

    transformControlSwitcher() {
        return (
            <Html
                center={false}
                position={[this.max.x * 1.1, this.max.y * 1.1, this.max.z * 1.1]}>

                {
                    this.state.transformControl !== "rotate" &&
                    <IconButton onClick={(a) => this.setState({ transformControl: "rotate" })}>
                        <RotateRightOutlinedIcon />
                    </IconButton>
                }
                {
                    this.state.transformControl !== "translate" &&
                    <IconButton onClick={(a) => this.setState({ transformControl: "translate" })}>
                        <LocationSearchingOutlinedIcon />
                    </IconButton>
                }

            </Html>
        );
    }

    propertiesBox() {
        return (
            <Html
                center={false}
                position={[this.max.x * 1.1, undefined, this.max.z * 1.1]}>
                <div className={"properties-box"}>
                    {this.propertiesList(this.props.shape.adjustables)}
                </div>
            </Html>
        );
    }


    componentDidMount() {

        this.updateGeom();

        if (this.transformer.current) {
            const { current: controls } = this.transformer
            this.controlsCallback = (event) => {
                this.props.orbitControls.current.enabled = !event.value;

                this.props.shape.adjustables.position = controls.worldPosition.clone();
                this.props.shape.adjustables.quaternion = controls.worldQuaternion.clone();

                this.setState({
                    position: this.props.shape.adjustables.position,
                    quaternion: this.props.shape.adjustables.quaternion,
                    euler: this.state.euler.setFromQuaternion(this.props.shape.adjustables.quaternion, THREE.Euler.DefaultOrder, true),
                });
            }
            controls.addEventListener('dragging-changed', this.controlsCallback)
            return () => controls.removeEventListener('dragging-changed', this.controlsCallback)
        }

    }

    componentWillUnmount() {
        if (this.transformer.current && this.controlsCallback) {
            const { current: controls } = this.transformer;
            controls.removeEventListener('dragging-changed', this.controlsCallback);
        }
    }

    updateGeom() {
        const geomNew = CSG.toGeometry(this.props.shape.csg);
        const geom = this.geom.current;

        geom.attributes.position = geomNew.attributes.position;
        geom.attributes.normal = geomNew.attributes.normal;

        if (geomNew.attributes.uv) {
            geom.attributes.uv = geomNew.attributes.uv;
        }
        let index;
        if (geomNew.index) {
            geom.attributes.index = geomNew.index.array;
        } else {
            if (geom.attributes.index) {
                // seems wrong :S
                geom.attributes.index.array = new Array((geom.attributes.position.array.length / geom.attributes.position.itemSize) | 0);
                for (let i = 0; i < index.length; i++) {
                    index[i] = i
                }
            }

        }

        geom.attributes.position.needsUpdate = true

        geom.computeVertexNormals();
        geom.computeBoundingSphere();
        geom.computeBoundingBox();

        this.min = this.geom.current.boundingBox.min;
        this.max = this.geom.current.boundingBox.max;
        this.width = this.max.x - this.min.x;
        this.depth = this.max.y - this.min.y;
        this.height = this.max.z - this.min.z;

        this.setState({
            geom: geom
        });

    }

    render() {
        return (
            <>
                {this.state.active && !this.props.disableControls && this.props.store.selectedMeshs[this.props.store.selectedMeshs.length - 1] === this.mesh && this.propertiesBox()}

                <TransformControls
                    ref={this.transformer}
                    showX={this.state.active && !this.props.disableControls}
                    showY={this.state.active && !this.props.disableControls}
                    showZ={this.state.active && !this.props.disableControls}
                    mode={this.state.transformControl}
                    position={new THREE.Vector3(this.state.position.x, this.state.position.y, this.state.position.z)}
                    quaternion={new THREE.Quaternion(this.state.quaternion.x, this.state.quaternion.y, this.state.quaternion.z, this.state.quaternion.w)}
                    size={0.75}
                >

                    {this.state.active && !this.props.disableControls && this.transformControlSwitcher()}

                    <mesh
                        ref={this.mesh}
                        castShadow
                        rotation={this.props.rotation}
                        position={this.props.position}
                        onClick={(e) => {

                            if (e.intersections[0].object !== this.mesh.current) {
                                return;
                            }

                            if (this.props.onClick) {

                                this.props.onClick(this.props.shape);
                            }

                            if (this.props.disableControls) {
                                return;
                            }

                            var selected = useStore.getState().selected;
                            const newActive = !(this.state.active);
                            selected = selected.filter(sv => sv !== this.props.shape);
                            if (newActive) {
                                selected.push(this.props.shape);
                            }
                            useStore.setState({ selected: selected });

                            var selectedMeshs = useStore.getState().selectedMeshs;
                            selectedMeshs = selectedMeshs.filter(sv => sv !== this.mesh);
                            if (newActive) {
                                selectedMeshs.push(this.mesh);
                            }
                            useStore.setState({ selectedMeshs: selectedMeshs });

                            this.setState({
                                active: newActive,
                            })

                        }}>
                        <bufferGeometry ref={this.geom} />
                        <meshPhongMaterial
                            flatShading
                            color={this.state.hole ? "black" : this.state.color.toHex()}
                            opacity={this.state.hole ? 0.5 : this.state.color.alpha}
                            transparent={true}
                            roughness={1} // The roughness of the material - Defaults to 1
                            metalness={0.0} // The metalness of the material - Defaults to 0
                            polygonOffset={true}
                            polygonOffsetFactor={0.5}
                            polygonOffsetUnits={1}
                        />
                    </mesh>

                    {/* {this.state.csg.polygons.length < 10000 &&
                        <EdgeGeometryView
                            csg={this.state.csg}
                            active={this.state.active}
                            shapeColor={this.state.color}
                            angle={1}
                            rotation={this.props.rotation}
                            position={this.props.position} />
                    } */}
                </TransformControls>


            </>);
    }

}

export default withStore(ShapeView);