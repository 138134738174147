import Bounds from './Bounds';

export default class Assembly {
    constructor() {
        this.name = "Assembly";
        this.shapes = [];
    }

    add(shape) {
        this.shapes.push(shape);
        shape.parent = this;
    }

    remove(shape) {
        this.shapes = this.shapes.filter(s => s !== shape);
        if (shape.parent === this) {
            shape.parent = null;
        }
    }

    bounds() {
        const bounds = new Bounds();
        this.shapes.forEach(shape => {
            bounds.addBounds(shape.csg.getBounds());
        })
        return bounds;
    }
}