

export function approxEquals(v1, v2, eps = 1e-5) {
    return Math.abs(v1.x - v2.x) < eps
        && Math.abs(v1.y - v2.y) < eps
        && Math.abs(v1.z - v2.z) < eps;
}

export function approxZero(v, eps = 1e-5) {
    return Math.abs(v.x) < eps
        && Math.abs(v.y) < eps
        && Math.abs(v.z) < eps;
}
