import Shape from "./Shape";
import { CSG } from "./CSG";
import Color from "./Color";

export default class Cone extends Shape {
    constructor(radius = 1, height = 2, radialSegments = 4) {
        super();
        this.adjustables.color = new Color(1., 1., 0.)
        this.setAdjustables(
            {
                radius, // radius
                height, // height
                radialSegments, //radial segments,
                type: {
                    radius: 'double',
                    height: 'double',
                    radialSegments: 'int',
                }
            }
        );
    }

    clone() {
        var shape = new Cone();
        this.cloneAttributes(shape, this);
        return shape;
    }

    make() {
        this.csg = CSG.cone(
            this.adjustables.radius, // radius
            this.adjustables.height, // height
            this.adjustables.radialSegments, //radial segments,
        );
    }

}