import React from 'react';
import { Line } from '@react-three/drei'

function Plane({ position, onClick, rotation, args }) {
    return (
        <mesh onClick={onClick} position={position} rotation={rotation}>
            <planeBufferGeometry attach="geometry" args={args} />
            <meshBasicMaterial
                attach="material"
                color="blue"
                opacity={0.00}
                transparent
            />
        </mesh>
    );
}

const WorkPlane = (props) => {
    const majorLineThickness = 0.2;

    var position = props.position;
    var onClick = props.onClick;
    var size = props.size;
    var inchPerDiv = props.inchPerDiv;
    var rotation = props.rotation;

    var ans = [];
    var halfWidth = size[0] / 2;
    var halfHeight = size[1] / 2;
    for (let i = 1; i < halfWidth / inchPerDiv; i++) {
        ans.push(<Line
            key={i + "-width"}
            points={[[inchPerDiv * i, -halfHeight, 0], [inchPerDiv * i, halfHeight, 0.]]}
            color={'blue'}
            // vertexColors={colors}
            lineWidth={majorLineThickness}
            dashed={false} />);

        ans.push(<Line
            key={i + "-neg-width"}
            points={[[-inchPerDiv * i, -halfHeight, 0], [-inchPerDiv * i, halfHeight, 0.]]}
            color={'blue'}
            // vertexColors={colors}
            lineWidth={majorLineThickness}
            dashed={false} />);
    }

    for (let i = 1; i < halfHeight / inchPerDiv; i++) {
        ans.push(<Line
            key={i + "-height"}
            points={[[-halfWidth, inchPerDiv * i, 0], [halfWidth, inchPerDiv * i, 0.]]}
            color={'blue'}
            // vertexColors={colors}
            lineWidth={majorLineThickness}
            dashed={false} />);

        ans.push(<Line
            key={i + "-neg-height"}
            points={[[-halfWidth, -inchPerDiv * i, 0], [halfWidth, -inchPerDiv * i, 0.]]}
            color={'blue'}
            // vertexColors={colors}
            lineWidth={majorLineThickness}
            dashed={false} />);
    }

    // Center Horizontal Line
    ans.push(<Line
        key={"horizontal"}
        points={[[-halfWidth, 0, 0], [halfWidth, 0, 0.]]}
        color={'white'}
        // vertexColors={colors}
        lineWidth={majorLineThickness}
        dashed={false} />);

    // Center Vertical Line
    ans.push(<Line
        key={"vertical"}
        points={[[0, -halfHeight, 0], [0., halfHeight, 0.]]}
        color={'white'}
        // vertexColors={colors}
        lineWidth={majorLineThickness}
        dashed={false} />);

    // Border
    ans.push(<Line
        key={"border-left"}
        points={[[-halfWidth, -halfHeight, 0], [-halfWidth, halfHeight, 0.]]}
        color={'white'}
        // vertexColors={colors}
        lineWidth={majorLineThickness}
        dashed={false} />);

    ans.push(<Line
        key={"border-bottom"}
        points={[[-halfWidth, -halfHeight, 0], [halfWidth, -halfHeight, 0.]]}
        color={'white'}
        // vertexColors={colors}
        lineWidth={majorLineThickness}
        dashed={false} />);

    ans.push(<Line
        key={"border-right"}
        points={[[halfWidth, -halfHeight, 0], [halfWidth, halfHeight, 0.]]}
        color={'white'}
        // vertexColors={colors}
        lineWidth={majorLineThickness}
        dashed={false} />);

    ans.push(<Line
        key={"border-top"}
        points={[[halfWidth, halfHeight, 0], [-halfWidth, halfHeight, 0.]]}
        color={'white'}
        // vertexColors={colors}
        lineWidth={majorLineThickness}
        dashed={false} />);

    ans.push(<Plane key={"plane"} receiveShadow position={position} onClick={onClick} rotation={rotation} args={size} />)

    return ans
}

export default WorkPlane;
